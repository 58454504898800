import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-span-6 lg:col-start-8 lg:col-end-13 mt-4 md:mt-0"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "flex-grow mb-3" }
const _hoisted_5 = { class: "ml-2 lg:mt-3" }
const _hoisted_6 = { class: "ml-2 lg:mt-3" }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_9 = { class: "typeset-8" }
const _hoisted_10 = {
  key: 3,
  disabled: "",
  class: "button w-full mb-5"
}
const _hoisted_11 = {
  key: 5,
  class: "pt-4 mb-8 text-format"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 6,
  class: "mb-5"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.artwork)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.error)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.error.message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString($setup.artwork.title), 1),
          _createElementVNode("div", _hoisted_5, [
            ($setup.activeProduct?.id)
              ? (_openBlock(), _createBlock($setup["PinterestButton"], { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            ($setup.activeProduct?.id)
              ? (_openBlock(), _createBlock($setup["WishListButton"], {
                  key: 0,
                  "product-id": $setup.activeProduct.id
                }, null, 8, ["product-id"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("a", {
          class: "block typeset-4 mb-3 hover:underline",
          href: $setup.artistHref(),
          "data-test": "artist-name"
        }, _toDisplayString($setup.artistName()), 9, _hoisted_7),
        ($setup.productTypeDescription())
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($setup.productTypeDescription()), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_9, "#" + _toDisplayString($setup.artwork.id), 1),
        _createVNode($setup["ArtworkRating"]),
        ($setup.showProductForm() && $setup.activeOptions)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode($setup["ProductForm"], {
                "active-options": $setup.activeOptions,
                "active-product": $setup.activeProduct,
                "artwork-product-list": $setup.artwork.products,
                class: "mt-12 mb-5",
                onUpdateOptions: $setup.updateOptions,
                onAddToCart: $setup.addProductToCart
              }, null, 8, ["active-options", "active-product", "artwork-product-list"]),
              ($setup.priceOver(499))
                ? (_openBlock(), _createBlock($setup["KmMakeEnquiry"], {
                    key: 0,
                    "product-id": $setup.activeProduct?.id,
                    "product-title": $setup.artwork.title,
                    class: "mb-5"
                  }, null, 8, ["product-id", "product-title"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("button", _hoisted_10, "Sold")),
        ($setup.profile.internal)
          ? (_openBlock(), _createElementBlock("button", {
              key: 4,
              ref: "defaultProductButton",
              class: "button w-full mb-5",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.updateDefaultProduct()))
            }, " Update default product ", 512))
          : _createCommentVNode("", true),
        ($props.showDescription && $setup.productDescriptionText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode($setup["KmReadMore"], null, {
                default: _withCtx(() => [
                  _createElementVNode("div", { innerHTML: $setup.productDescriptionText }, null, 8, _hoisted_12)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        ($setup.artwork.copyright)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString($setup.artwork.copyright), 1))
          : _createCommentVNode("", true),
        _createVNode($setup["KmPartnership"]),
        ($props.showPanels)
          ? (_openBlock(), _createBlock($setup["KmAccordion"], {
              key: 7,
              panels: $setup.accordionPanels,
              "default-open": ""
            }))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-3" }, "Tags", -1)),
        _createVNode($setup["KmTags"], {
          tags: $setup.artwork.tags,
          "created-at": $setup.artwork.created_at
        }, null, 8, ["tags", "created-at"])
      ]))
    : _createCommentVNode("", true)
}